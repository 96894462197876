<template>
    <div>
    
        <!-- <v-snackbar top v-model="dialog" :timeout="4000">
            <h3><v-icon :color="dialogType.color" class="mx-2">{{dialogType.icon}}</v-icon>{{data.text}}</h3>
        </v-snackbar> -->
    
        <!-- <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="400">
            <v-card dark>
                <v-toolbar>
                    <v-toolbar-title>
                        <v-icon :color="dialogType.color" class="mx-2">{{dialogType.icon}}</v-icon>{{data.text}}
                    </v-toolbar-title>
                </v-toolbar>
            </v-card>
        </v-dialog> -->
    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
                dialog: false,
                data: {
                    type: '',
                    text: ''
                }
            }
        },
    
        computed: {
            dialogType() {
                if (this.data.type == 'error') {
                    return {
                        color: 'pink',
                        icon: 'cancel'
                    }
                } else if (this.data.type == 'info') {
                    return {
                        color: 'orange',
                        icon: 'info'
                    }
                } else {
                    return {
                        color: 'teal',
                        icon: 'check_circle'
                    }
                }
            }
        },
    
        watch: {
            dialog(val) {
                if (!val) return
    
                setTimeout(() => (this.dialog = false), 7000)
            },
        },
    
        created() {
    
            this.$eventBus.$on('send-data', (data) => {
                this.dialog = true
                this.data = data
                this.showIt(data)
            });
    
            this.$service.eventBus.on('send-data', (data) => {
                this.dialog = true
                this.data = data
                this.showIt(data)
            });
        },
    
        methods: {
            showIt(data) {
                if (data.type == 'error') {
                    this.$toast.error(data.text, {
                        position: "top-left",
                        timeout: 7000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: true,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                } else if (data.type == 'info') {
                    this.$toast.warning(data.text, {
                        position: "top-left",
                        timeout: 7000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: true,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                } else {
                    this.$toast.success(data.text, {
                        position: "top-left",
                        timeout: 7000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: true,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                }
    
            }
        },
    
    }
    </script>
    